import React from "react";
import "../css/Navbar.css";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  return (
    <div className="container-fluid navbar-container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <HashLink className="navbar-brand nav-logo" smooth to="/#">
          Diamond Day
          <br />
          <p>events</p>
        </HashLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-link active" href="/">
              Home
            </a>
            <HashLink className="nav-link" smooth to="/#about">
              About
            </HashLink>
            <HashLink className="nav-link" smooth to="/#services">
              Services
            </HashLink>
            <HashLink className="nav-link" smooth to="/#contact">
              Contact
            </HashLink>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
