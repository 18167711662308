import React from "react";
import "../css/About.css";
const About = () => {
  return (
    <div className="about-container container-fluid" id="about">
      <div className="row">
        <div className="col-md-6 text-center"></div>
        <div className="col-md-6">
          <div data-aos="fade-down" data-aos-offset="300">
            <div className="about-header">ABOUT DIAMOND DAY EVENTS</div>

            <div className="about-body pt-2">
              Hello! I am Diane Phan, the owner of Diamond Day Events. I
              especially fell in love with all things wedding in the process of
              planning my Hawaii destination wedding—which unfortunately was
              canceled due to COVID-19. In the end, after all the disappointment
              from having to cancel our wedding due to the pandemic, the
              planning and coordinating, on a whim, my now-husband and I decided
              to elope 2 weeks before our would be wedding date in Hawaii.
              Within 2 weeks, I planned a small elopement ceremony at my best
              friend’s lake house backyard. We kept our original wedding date
              and my best friend married us, I secured a talented photographer,
              videographer and florist—and it ended up be the most perfect day.
              <br />
              <br />
              <div data-aos="fade-down" data-aos-offset="300">
                {" "}
                Experiencing all of this, fueled my love for wanting to help
                brides and grooms ensure that their special day is everything
                they envisioned. I know firsthand, how expensive weddings can
                cost and how stressful everything leading up to the day can be.
                I want to offer quality services, specializing in day of
                coordination to carry out all the little details so all the
                bride and groom has to do is focus on their special day and each
                other. I pride myself in making sure couples trust me with one
                of the most important days of their lives and that guests enjoy
                each moment and detail. Not only do I want to help create
                beautiful moments for weddings, but also other special life
                events and milestones including bridal events, baby showers and
                birthday parties. Let’s make your event dreams come true!
              </div>
              <br />
              <br />
            </div>
          </div>
          <div data-aos="fade-down" data-aos-offset="300">
            <div className="about-facts pt-2">
              <h5>Fun facts:</h5>
              <ul>
                <li>
                  I am fluent in Vietnamese (need a day of coordinator who also
                  speaks Vietnamese? I’m you’re girl!)
                </li>
                <li>
                  The inspiration behind the name of my event coordination and
                  planning services comes from a combination of my husband’s and
                  my name Diane + Raymond (you might see him behind the scene
                  with me!).
                </li>
                <li>
                  My husband and I took home our first daughter on our one year
                  wedding anniversary. -We recently welcomed another sweet baby
                  girl.
                </li>
                <li>
                  Our little family loves traveling, trying new foods and
                  partying with our big extended group of family and friends!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
