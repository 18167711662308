import React, { useState } from "react";
import "../css/Contact.css";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <div className="result-sent">
      <p>
        Thank you for reaching out to us! Please allow 24-48 hours for an email
        response. We can't wait to celebrate with you!{" "}
      </p>
    </div>
  );
};

export default function Contact() {
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ls2jl5z",
        "template_l9doxqo",
        e.target,
        "yodnrrH9gC6ugoVBs"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {}
      );
    e.target.reset();

    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 4000);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const form = e.currentTarget;
  //   if (form.checkValidity() === false) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }

  //   // setValidated(true);
  // };

  return (
    <body>
      <div>
        <div className="container-fluid contact-container " id="contact">
          <div className="contact-header">
            <div data-aos="zoom-in" data-aos-offset="300">
              {" "}
              <h1>Let's make your day unforgetable!</h1>
            </div>{" "}
            <br />
          </div>
          <div className=" row contact-info">
            <div className="col-md-3 ">
              {" "}
              <i class="bi bi-instagram icons"></i>
              <a
                href="https://www.instagram.com/diamonddayevents/"
                target="_blank"
              >
                DiamondDayEvents
              </a>
            </div>
            <div className="col-md-4 ">
              <i class="bi bi-envelope icons"></i>
              <a href="mailto: Diamonddayevents@gmail.com">
                Diamonddayevents@gmail.com
              </a>
            </div>

            <div className="col-md-3 ">
              <i class="bi bi-telephone icons"></i>
              <a href="tel:+12066013933">206-601-3933</a>
            </div>
          </div>

          <div className="row contact-form">
            <div className="col-md-3 contact-right"></div>
            <div className="col-md-9 form-body-text">
              <form className="row g-3 request-form" onSubmit={sendEmail}>
                <div className="col-md-6 ">
                  <label htmlFor="fullname" className=" form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="fullname"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="partner" className="form-label">
                    Partner's Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="partner"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    name="email"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="phone"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="date" className="form-label">
                    Event Date
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    name="date"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="location" className="form-label">
                    Venue Name and Location
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="location"
                    required
                  />
                </div>{" "}
                <div className="col-md-6">
                  <label htmlFor="budget" className="form-label">
                    Estimated Total Budget
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="budget"
                    required
                  />
                </div>{" "}
                <div className="col-md-6">
                  <label htmlFor="guestcount" className="form-label">
                    Invited Guest Count
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="guestcount"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="details" className="form-label">
                    Tell me a little more about your wedding details (theme,
                    colors, etc.) and any vendors already booked.
                  </label>
                  <textarea
                    type="text"
                    required
                    className="form-control form-control-sm"
                    name="details"
                    rows={8}
                  />{" "}
                </div>
                <div className="col-md-12 pt-2 d-grid pb-2">
                  <button type="submit" className="btn btn-dark submit-btn  ">
                    Submit
                  </button>
                </div>
                <div>{result ? <Result /> : null}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
