import React from "react";
import Homepage from "./Homepage";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
const Landing = () => {
  return (
    <div className="container-fluid">
      <Homepage />
      <About />
      <Services />
      <Contact />
    </div>
  );
};

export default Landing;
