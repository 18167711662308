import React from "react";
import "../css/Homepage.css";
import { HashLink } from "react-router-hash-link";

const Homepage = () => {
  return (
    <div className="container-fluid homepage-container">
      <div className="row">
        {" "}
        <div className="col-md-6 header-img image"></div>
        <div className="col-md-6 header-img image2"></div>
      </div>
      <div className="heading text-center">
        <div data-aos="fade-down">
          {" "}
          <h1>Day of Coordination and Full Service Special Event Planning</h1>
        </div>
      </div>
      <div className="button-container text-center">
        <div data-aos="fade-down">
          {" "}
          <button type="button" class="btn btn-light button">
            <HashLink className="nav-link" smooth to="/#contact">
              Inquire Now
            </HashLink>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
