import React from "react";
import "../css/Services.css";
import image1 from "../img/download (2).png";

const Services = () => {
  return (
    <div className="container-fluid service-container" id="services">
      {" "}
      <div className="row">
        <div className="col-md-6">
          <div className="service-header pb-3">Services</div>
          <div className="service-body-block pb-5">
            <div data-aos="fade-down" data-aos-delay="300">
              <div className="service-title">
                <h5>Day of Coordination Wedding Services</h5>
              </div>{" "}
              <div className="service-body">
                For those couples who already have their large plans solidified
                but desire some help ironing out last minute details. I provide
                detail service 2 months prior to nuptials to tie up all loose
                ends, we review all the vendor’s contracts and create a timeline
                for the big day. We contact all vendors and service providers
                with introductions and update vendors on any last minute
                requests from the bride and groom. This package includes a
                primary coordinator who will be present from set up to reception
                breakdown. Day of Coordination service prices begin at $1200*.
              </div>
            </div>
          </div>
          <div className="service-body-block pb-4">
            <div className="service-title">
              <div data-aos="fade-down" data-aos-delay="600">
                <h5>Special Event Planning</h5>
                <div className="service-body">
                  Bridal events, baby showers, and birthday parties — pricing
                  varies, please inquire for additional information.
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {" "}
          <img
            src={image1}
            className="rounded mx-auto d-block services-image"
            alt="flowers"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
