import React from "react";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid footer-section">
      <div className="copyright">
        <small>
          © 2023 DiamondDayEvents | Dev:{" "}
          <a href="mailto: selina.pn@outlook.com">selinapn</a>
        </small>
      </div>
    </div>
  );
};

export default Footer;
