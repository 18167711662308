import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import About from "./components/About";
import Services from "./components/Services";
import Landing from "./components/Landing";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Scrolltotop from "./components/ScrollToTop";
function App() {
  return (
    <Router>
      <Navbar />
      <Scrolltotop />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services" component={Services} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
